import React from 'react';
import SocialMedia from './SocialMedia';

function Footer() {
  return (
    <footer className="footer">
      <h2>Do you want more info?</h2>
      <SocialMedia />
      <span>© Angely Granados 2020</span>
    </footer>
  );
}

export default Footer;