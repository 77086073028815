import React from 'react';

function Bar({ title, percent, color }) {

  const style = {
    width: percent,
    backgroundColor: color
  }
  return (
    <li className="skills-container__bar">
      <span className="skills-container__text">{title}</span>
      <div id={title} className="skills-container__status" style={style}></div>

    </li>
  );
}

export default Bar;