import React from "react";
import Card from "./Card";

function Projects() {
  const projects = [
    {
      title: "To-Do app",
      image: "../assets/images/projects/recipes-app.PNG",
      copy:
        "Fullstack project created using MERN stack: React, Redux, Express, Mongo DB, Node",
      url: "https://todo.angelygranados.com/",
      github: "https://github.com/angelygranados/to_do_app_frontend",
    },
    {
      title: "Press Hotel",
      image: "../assets/images/projects/project1.png",
      copy:
        "Landing page developed using semantic html and css, responsive design",
      url: "https://developer-test-abstraer-hkxqubufg.now.sh/",
      github: "https://github.com/angelygranados/presshotel-abstraer",
    },
    {
      title: "Titanic App",
      image: "../assets/images/projects/project3.png",
      copy: "Proyect created with React, Hooks and Sass",
      url: "https://titanic-front.vercel.app/",
      github: "https://github.com/angelygranados/titanic-front",
    },
    {
      title: "Recipes admin app",
      image: "../assets/images/projects/recipes-app.PNG",
      copy:
        "Admin app created using React, Redux and Router. Current Development",
      url: null,
      github: "https://github.com/angelygranados/recipes-app",
    },
  ];
  return (
    <section className="projects animate__animated" id="projects">
      <h2 id="projects-title">Projects</h2>
      {projects.map((project) => (
        <Card key={project.title} {...project} />
      ))}
    </section>
  );
}

export default Projects;
