import React from 'react';
import GithubIcon from '../assets/images/icons/github.png';
import TwitterIcon from '../assets/images/icons/twitter.png';
import LinkedInIcon from '../assets/images/icons/linkedin.png';
import MediumIcon from '../assets/images/icons/medium.png';

function SocialMedia() {
  return (
    <div className="socialmedia ">
      <a className="Github animate__animated animate__fadeIn animate__delay-1s animate__slow" href="https://github.com/angelygranados" target="_blank" rel="noopener noreferrer" ><img src={GithubIcon} alt="Github" /></a>
      <a className="Twitter animate__animated animate__fadeIn animate__delay-2s animate__slow" href="https://twitter.com/angelygranados" target="_blank" rel="noopener noreferrer" ><img src={TwitterIcon} alt="Twitter" /></a>
      <a className="LinkedIn animate__animated animate__fadeIn animate__delay-3s animate__slow" href="https://www.linkedin.com/in/angelygranados/" target="_blank" rel="noopener noreferrer" ><img src={LinkedInIcon} alt="LinkedIn" /></a>
      <a className="Medium animate__animated animate__fadeIn animate__delay-4s animate__slow" href="https://medium.com/@angelygranados" target="_blank" rel="noopener noreferrer" ><img src={MediumIcon} alt="Medium" /></a>
    </div>
  );
}

export default SocialMedia;