import React from 'react';

function Card({ title, copy, url, github }) {
  let display
  if (url == null) {
    display = {
      display: 'none'
    }
  }

  return (
    <article className="card">
      <div className="content">
        <h3 className="title">{title}</h3>
        <p>{copy}</p>
        <a href={url} target="_blank" rel="noopener noreferrer" className="offset" style={display}>Visit website</a>
        <a href={github} target="_blank" rel="noopener noreferrer" className="offset">View repository</a>
      </div>
    </article>
  );
};

export default Card;