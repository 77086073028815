import React from 'react';
import Bar from './Bar';
function Skills() {
  const techSkills = [
    {
      title: "React",
      percent: "70%",
      color: "#ffadad"
    },
    {
      title: "JavaScript",
      percent: "90%",
      color: "#ffd6a5"
    },
    {
      title: "Redux",
      percent: "50%",
      color: "#fdffb6"
    },
    {
      title: "CSS/Sass",
      percent: "80%",
      color: "#caffbf"
    }
  ]
  const softSkills = [
    {
      title: "UX/UI",
      percent: "60%",
      color: "#9bf6ff"
    },
    {
      title: "English",
      percent: "75%",
      color: "#bdb2ff"
    },
    {
      title: "Creative writting",
      percent: "60%",
      color: "#ffc6ff"
    },
    {
      title: "SEO",
      percent: "50%",
      color: "#DCEDC2"
    },

  ]
  return (
    <section className="skills animate__animated" id="skills">
      <h2>Skills</h2>
      <ul className="skills-container">
        {techSkills.map((skill =>
          <Bar key={skill.title} {...skill} />
        ))}
      </ul>
      <ul className="skills-container">
        {softSkills.map((skill =>
          <Bar key={skill.title} {...skill} />
        ))}
      </ul>
    </section>
  );
}

export default Skills;