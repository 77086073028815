import React from 'react';

const PostCard = ({ title, content, pubDate, author, thumbnail, link, categories }) => {
  function toText(node) {
    let tag = document.createElement('div')
    tag.innerHTML = node
    node = tag.innerText
    return node
  }
  function shortenText(text, startingPoint, maxLength) {
    return text.length > maxLength ?
      text.slice(startingPoint, maxLength) :
      text
  }
  const background = {
    backgroundImage: 'url(' + thumbnail + ')'
  }
  return (
    <div className="blog-card">
      <div className="meta">
        <div className="photo" style={background}></div>
        <ul className="details">
          <li className="author">{author}</li>
          <li className="date">{shortenText(pubDate, 0, 10)}</li>
          <li className="tags">
            <ul className="tags__list">
              {categories.map((tag, index) =>
                <li key={index}>{tag}</li>
              )}
            </ul>
          </li>
        </ul>
      </div>
      <div className="description">
        <h2>{title}</h2>
        <p>{shortenText(toText(content), 0, 290) + '...'}</p>
        <p className="read-more">
          <a href={link}>Read More</a>
        </p>
      </div>
    </div >
  )
};

export default PostCard;