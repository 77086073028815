import React, { useState, useEffect } from 'react';
import Post from './PostCard';

function Posts() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@angelygranados")
      .then(response => response.json())
      .then(data => {
        let items = data.items.filter(item => item.categories.length > 0);
        return setBlogs(items);
      })
  }, []);

  return (
    <section className="blog" id="blogs">
      <h2 className="animate__animated" id="blogs-title">My publications</h2>
      {blogs.length > 0 && (
        <div className="posts animate__animated">
          {blogs.map((item) =>
            <Post key={item.title} {...item} />
          )}
        </div>
      )}

    </section>
  );
};

export default Posts;