import React from 'react';
import Posts from './Posts';
import SocialMedia from './SocialMedia';
import Skills from './Skills';
import Projects from './Projects';

function App() {
  return (
    <main className="App">
      <section className="hero">
        <h1 className="hero__title animate__animated animate__fadeInLeft animate__slower">Hi, I'm <span>Angely Granados</span><span>react frontend developer <br></br>passionate for accessibility <br></br>and UX/UI design</span></h1>
        <SocialMedia />
        <div className="overlay"></div>
      </section>
      <Skills />
      <Projects />
      <Posts />
    </main>
  );
}

export default App;
