import React from 'react';

function Header() {
  window.addEventListener(
    "scroll",
    function () {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      if (st > windowHeight) {
        document.getElementById("header").classList.add("fixed");
      } else {
        document.getElementById("header").classList.remove("fixed");
      }
    },
    false
  );
  return (
    <header className="header" id="header">
      <div className="header__menu animate__animated">
        <ul id="menu">
          <li><a className="offset" href="#skills">Skills</a></li>
          <li><a className="offset" href="#projects">Projects</a></li>
          <li><a className="offset" href="#blogs">Blog</a></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;